import IndexedDbHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';

import type * as GlobalSettingModule from 'o365.pwa.declaration.shared.dexie.objectStores.GlobalSetting.d.ts';

export class GlobalSetting implements GlobalSettingModule.GlobalSetting {
    static objectStoreDexieSchema: string = "++id";

    id!: number;
    cdnUrl!: string;

    constructor(cdnUrl: string) {
        this.cdnUrl = cdnUrl;
    }

    public async save(): Promise<void> {
        await IndexedDbHandler.updateGlobalSetting(this);
    }

    public async delete(): Promise<void> {
        await IndexedDbHandler.deleteGlobalSetting(this);
    }

    public async forceReload(): Promise<GlobalSetting | null> {
        return await IndexedDbHandler.getGlobalSettingFromIndexedDB();
    }
}

export default GlobalSetting;
